import React, { useEffect, useState } from "react";
import "../../style/style.css";
import axios from "axios";
// import internet from "../../assets/images/myinternet.jpg";
// import myev from "../../assets/images/my-ev.png";
// import weather from "../../assets/images/weather.svg";
// import livebg from "../../assets/images/live.png";
// import icon from "../../assets/images/togle-img.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLanguage } from "../../utils/LanguageContext";
import MenuIcon from "@mui/icons-material/Menu";
// import { Link } from "react-router-dom";
// import NewsSlider from "./LiveNewsSlider";
import LiveNewsSlider from "./LiveNewsSlider";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
const Live = () => {
  const { translate } = useLanguage();
  const [liveUrl, setLiveUrl] = useState([])
  const getLiveNews = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/bmw/news/getLive`)
      console.log(">>>", response.data)
      setLiveUrl(response.data)
    } catch (error) {
      console.log("Error fetching Live:", error)
    }
  }
  useEffect(() => {
    getLiveNews()
  }, [])
  return (
    <div>
      <section>
        <div className="live-news padd features">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <ul className="p-0">
                  <li className="frist_child">
                    <MenuIcon style={{ color: "red", marginRight: "10px", marginBottom: '2px' }} />
                    <strong style={{ fontSize: '18px', textTransform: 'uppercase' }}>
                      {translate("liveHeading")}
                    </strong>
                  </li>
                </ul>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="box">
                      <div className="responsive-iframe-container">
                        <iframe className="w-100 h-100"
                          src={liveUrl[0]?.videoUrl}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                  <LiveNewsSlider liveUrl={liveUrl} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Live;
