import React from "react";

const Loader = () => {
    return (
        <div class="loader_box">
            <div class="circle-border">
                <div class="circle-core"></div>
            </div>
        </div>
    );
};

export default Loader;
