import React, { useEffect, useState } from "react";
import Navbar from "../components/common/Navbar";
import "../style/style.css";
import axios from "axios";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
// import LinkedIn from '@mui/icons-material/LinkedIn';
import "bootstrap/dist/css/bootstrap.min.css";
import PinterestIcon from "@mui/icons-material/Pinterest";
import { Link } from "react-router-dom";
// import img from '../../assets/images/latest-news.png'
import add from "../assets/images/add1.png";
import { useParams } from "react-router-dom";
import { useLanguage } from "../utils/LanguageContext";
import add2 from "../assets/images/news_card.png";
import Footer from "../components/common/Footer";
import SEO from "../components/common/Seo";
// import NotFound from "./404";
// import author fom "../assets/images/user.png";
// import { Liquor } from "@mui/icons-material";
const NewsArticle = () => {
  // window.scroll(0, 0);
  const { id } = useParams();
  console.log(id, "<<<id");
  const { changeLanguage, language, translate } = useLanguage();
  const [article, setArticle] = useState(null);
  const [prevId, setPrevId] = useState(null);
  const [nextId, setNextId] = useState(null);
  const [handle, setHandle] = useState("");
  //  const [articles, setArticles] = useState([]);
  //  const [currentIndex, setCurrentIndex] = useState(0);

  const monthsEn = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const monthPu = [
    "ਜਨਵਰੀ",
    "ਫਰਵਰੀ",
    "ਮਾਰਚ",
    "ਅਪ੍ਰੈਲ",
    "ਮਈ",
    "ਜੂਨ",
    "ਜੁਲਾਈ",
    "ਅਗਸਤ",
    "ਸਤੰਬਰ",
    "ਅਕਤੂਬਰ",
    "ਨਵੰਬਰ",
    "ਦਸੰਬਰ",
  ]

  const getNewsById = async (lang, id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/bmw/news/getNews/${lang}/${id}`
      );
      console.log("News by ID current>>", response.data.current);
      setHandle(response.data.current?.handle)
      // Handle the fetched news data as needed, maybe set it to state or return it
      setArticle(response.data.current);
      // console.log("prevId>>", response.data.previousId)
      setPrevId(response.data.previousId);
      // console.log("nextId>>", response.data.nextId)
      setNextId(response.data.nextId);
    } catch (error) {
      console.log("Error fetching news by ID:", error); // You can handle this error in the component where you call this function
    }
  };
  console.log("handle", handle);
  useEffect(() => {
    // eslint-disable-next-line
    getNewsById(language, id);
  }, [language, id]);
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    const monthNames = language === 'en' ? monthsEn : monthPu
    return `${monthNames[parseInt(month) - 1]}-${day}-${year}`;
  };
  // useEffect(()=>{
  //   if(id !== handle){
  //   return <NotFound/>
  //   }
  // },[]);
  return (
    <div>
      <SEO title="newsarticle | MyPunjabiTv" description="" />
      <Navbar changeLanguage={changeLanguage} currentLanguage={language} />
      <section className="">
        <div className="live-news padd features latest-news blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="blog-card">
                  {article ? (
                    <div className="post-date-ribbon">
                      <div className="corner"></div>
                      {formatDate(article.createdAt)}
                    </div>
                  ) : (
                    "January-1-2024"
                  )}

                  <div className="about-right">
                    <div className="about-img">
                      {article ? (
                        <img
                          src={article.img}
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            width: "100%",
                            maxHeight: "500px",
                          }}
                          alt={article.title}
                        />
                      ) : (
                        <h3>Loading...</h3>
                      )}
                    </div>
                  </div>
                  <div className="section-tittle text-start">
                    {article ? <h3>{article.title}</h3> : <h3>Loading...</h3>}
                  </div>
                  {article ? (
                    <p className="about-pera1 " dangerouslySetInnerHTML={{ __html: article.description }}>

                    </p>
                  ) : (
                    <p className="about-pera1"> Loading...</p>
                  )}

                  <div className="PrevNext text-end" onClick={handleLinkClick}>
                    {prevId ? (
                      <Link to={`/news/${article.categoryHandle}/${prevId}`}> {translate("previousbutton")}</Link>
                    ) : (
                      <span style={{ color: "gray", marginRight: "10px" }}>
                        {translate("previousbutton")}
                      </span>
                    )}

                    {nextId ? (
                      <Link to={`/news/${article.categoryHandle}/${nextId}`}>{translate('nextBtn')} </Link>
                    ) : (
                      <span style={{ color: "gray", marginRight: "10px" }}>{translate('nextBtn')} </span>
                    )}
                  </div>

                  <div className="social-medialinks text-start">
                    <h5>{translate('followUs')}</h5>
                    <ul className="my-3">
                      <li>
                        <Link
                          to="https://www.facebook.com/tvmypunjabi"
                          target="_blank"
                        >
                          <FacebookIcon />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.instagram.com/mypunjabitv"
                          target="_blank"
                        >
                          <InstagramIcon />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://twitter.com/mypunjabitv"
                          target="_blank"
                        >
                          <TwitterIcon />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.youtube.com/@MYPunjabiTV"
                          target="_blank"
                        >
                          <YouTubeIcon />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://in.pinterest.com/mypunjabitv"
                          target="_blank"
                        >
                          {" "}
                          <PinterestIcon />{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <div className="about-author">
                        <div className="media">
                          <img
                            className="align-self-start mr-3 w-20"
                            src={article?.authorImg}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="media-body text-start">
                        <h6>
                          <strong>{translate("newsAuthorName")}</strong>
                        </h6>
                        <Link
                          to={article?.socialLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-decoration-none py-2"
                        >
                          {article?.author}
                        </Link>
                        {article && <p>{article?.about}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="blog-card">
                  <div className="news-poster d-none d-lg-block fixed">
                    <Link to='https://www.theabacus.in/' target="_blank">
                      <img src={add2} alt="" />
                    </Link>
                    <Link to='https://www.skypro.co.in/ ' target="_blank">
                      <img className="mt-5" src={add} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default NewsArticle;
